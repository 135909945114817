import { rfiHelperApi } from 'api'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'

export const updateUseCaseOld = (props: {
  fileKey: string
  id: string
  title: string
  client: string
  markets: string[]
  pitch_types: string[]
  sub_category: string
  agencyIds: string[]
  isFavorite: boolean
  isConfidential: boolean
  description: string
}) =>
  rfiHelperApi.patch<ProgressApiRes>(`/use-cases/${props.id}`, {
    fileKey: props.fileKey,
    title: props.title,
    client: props.client,
    markets: props.markets,
    pitchTypes: props.pitch_types,
    subCategory: props.sub_category,
    agencyIds: props.agencyIds,
    isFavorite: props.isFavorite,
    isConfidential: props.isConfidential,
    description: props.description,
  })
