/* eslint-disable react-hooks/exhaustive-deps */
import { MentionData } from '@draft-js-plugins/mention'
import {
  WppActionButton,
  WppIconChatMessage,
  WppIconClose,
  WppIconCopy,
  WppIconDone,
  WppIconEdit,
  WppIconMore,
  WppIconTrash,
  WppIconUserTag,
  WppListItem,
  WppMenuContext,
  WppSpinner,
  WppTextareaInput,
  WppTypography,
  WppTooltip,
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import Markdown from 'react-markdown'

import { useEditComment } from 'api/mutations/rfis/comments/useEditComment'
import { usePostComment } from 'api/mutations/rfis/comments/usePostComment'
import { usePatchRfiQuestionId } from 'api/mutations/rfis/usePatchRfiQuestionId'
import { useCreateNotification } from 'api/mutations/useCreateNotification'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import stylesMain from 'assets/main.module.scss'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { RfiComment, RfiMember, RfiQuestion } from 'types/rfis/rfi'

import styles from './ConversationMessage.module.scss'
import { Message } from '../ChatCmp'
import { CommentInput } from '../comment-input/CommentInput'
import { MessageType, ProjectActionType } from '../types'
import { hasRequiredPermission, isCommentOwner } from '../utils'
import { Role } from '@/types/users/userList'

const { EDIT_RFI_QUESTION, ADD_COMMENT, EDIT_COMMENT, DELETE_COMMENT, VIEW_COMMENT, COPY_TO_CLIPBOARD } =
  ProjectActionType

export type CommentAction = 'edit' | 'delete' | 'add'

interface Props {
  message: Message
  isTaskRunning?: boolean
  projectMembers: RfiMember[]
  question?: RfiQuestion | null
  handleUpdateCommentList: ({
    messageId,
    commentData,
  }: {
    messageId: string
    commentData: RfiComment
    action: CommentAction
  }) => void
  handleDeleteComment: ({ messageId, commentId }: { messageId: string; commentId: string }) => void
  setConversation: React.Dispatch<React.SetStateAction<Message[]>>
}

export const ConversationMessage = ({
  message,
  handleDeleteComment,
  projectMembers,
  isTaskRunning,
  handleUpdateCommentList,
  setConversation,
  question = null,
}: Props) => {
  const {
    osContext: {
      tenant: { id: tenantId },
      userDetails: { email: currentUserEmail, firstname: currentUserFirstname, lastname: currentUserLastname },
    },
  } = useOs()
  const { showToast } = useToast()
  const [updating, setUpdating] = useState<string | null>(null)
  const rfiQuestionId = message?.rfiQuestionId
  const parentQuestionId = message?.parentId || null
  const { mutateAsync: handleCreateNotification } = useCreateNotification()

  const projectMember = projectMembers.filter(member => member.memberDetail.email === currentUserEmail)?.[0]
  const userRole = projectMember?.role

  const scrollDivRef = useRef<HTMLDivElement>(null)
  const members: MentionData[] = useMemo(() => {
    return projectMembers.map(user => ({
      ...user,
      name: user.memberDetail.name,
      avatar: user.memberDetail.img,
      email: user.memberDetail.email,
    }))
  }, [projectMembers])

  const { mutateAsync: editProject, isPending } = usePatchRfiQuestionId()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: updating || '' },
    enabled: !!updating,
    refetchInterval: 2000,
  })

  const { mutateAsync: editComment, isPending: isEditingComment } = useEditComment({
    onSuccess: data => {
      handleUpdateCommentList({ messageId: message.id, commentData: data.data, action: 'edit' })
      scrollToView()
    },
    onError: e => {
      const err = e.response?.data as any
      showToast({
        type: 'error',
        message: err.response?.data?.message ?? 'An error occurred while editing comment',
      })
    },
  })

  const { mutateAsync: postComment, isPending: isPostingComment } = usePostComment({
    onSuccess: data => {
      handleUpdateCommentList({ messageId: message.id, commentData: data.data, action: 'add' })
      scrollToView()
    },
    onError: e => {
      const err = e.response?.data as any
      showToast({
        type: 'error',
        message: err.response?.data?.message ?? 'An error occurred while adding comment',
      })
    },
  })

  const [isEditQuestionOpen, setIsEditQuestionOpen] = useState(false)
  const [content, setContent] = useState('')

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.content)
    showToast({
      message: 'Message response copied to clipboard',
      type: 'success',
      duration: 4000,
    })
  }

  const handleChange = (event: any) => {
    setContent(event.target.value)
  }

  const handleCloseEdit = () => {
    setContent(message.content)
    setIsEditQuestionOpen(false)
  }

  const handleSubmit = async (question_id: string = rfiQuestionId || '', proposedAnswer: string = content) => {
    try {
      const progress = await editProject({ question_id, proposedAnswer })
      setIsEditQuestionOpen(false)
      setUpdating(progress.data.id)
    } catch (error) {
      showToast({
        message: 'Error updating question',
        type: 'error',
      })
    }
  }

  /* 
  TODO should be triggered on the backend  */
  const handleNotification = async (mentions: MentionData[], comment: string) => {
    const rfiQuestion = question

    const mentionEmails = mentions.reduce<string[]>((accum, mention) => {
      /* if a mention was deleted make sure the name is still found in the comment string */
      if (comment.includes(mention.name)) {
        accum.push(mention.email)
      }
      return accum
    }, [])
    try {
      await handleCreateNotification({
        notification: {
          title: 'Mentioned in a comment',
          body: `${currentUserFirstname} ${currentUserLastname} mentioned you in a comment on: ${rfiQuestion?.questionText || ''}`,
          recipients: {
            users: mentionEmails,
            groups: [],
          },
          scheduledAt: 'now',
          tenantId,
        },
      })
      showToast({
        type: 'success',
        message: 'Team member(s) were successfully mentioned',
      })
    } catch (e) {
      showToast({
        type: 'error',
        message: 'Team member(s) were not successfully mentioned',
      })
    }
  }
  const handlePostComment = async (comment: string, mentions?: MentionData[]) => {
    try {
      const res = await postComment({
        rfiQuestionId: rfiQuestionId!,
        commentText: comment,
        isQuestionComment: message.type === MessageType.QUESTION,
      })

      if (res.status === 200 && mentions?.length) {
        handleNotification(mentions, comment)
      }
    } catch (e) {
      showToast({
        message: (e as any).toString(),
        type: 'error',
        duration: 4000,
      })
    }
  }

  const handleEditComment = async (comment: string, commentId: string) => {
    try {
      await editComment({
        commentId,
        rfiQuestionId: rfiQuestionId!,
        commentText: comment,
        isQuestionComment: message.type === MessageType.QUESTION,
      })
    } catch (e) {
      showToast({
        message: (e as any).toString(),
        type: 'error',
        duration: 4000,
      })
    }
  }

  const scrollToView = () => {
    const scrollImmediateTimeout = setTimeout(() => {
      scrollDivRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }, 10)

    return () => {
      clearTimeout(scrollImmediateTimeout)
    }
  }

  useEffect(() => {
    if (taskStatus?.completed) {
      setUpdating(null)
      const question_id = parentQuestionId || rfiQuestionId || ''
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI_QUESTION, { question_id }] })
    }
  }, [taskStatus])

  useEffect(() => {
    setContent(message.content)
  }, [message.content])

  const handleToggleComments = (messageId: string) => {
    if (isEditQuestionOpen) {
      setIsEditQuestionOpen(false)
    }
    setConversation(prev => {
      return prev.map(msg => {
        if (msg.id === messageId) {
          const isACommentInEditMode = msg.comments.some(comment => comment.isEditMode)
          return {
            ...msg,
            comments: msg.comments.map(comment => ({ ...comment, isEditMode: false })), // close all edit mode
            commentState: {
              ...msg.commentState,
              showComments: !msg.commentState?.showComments,
              showInput: isACommentInEditMode ? false : !msg.commentState?.showInput,
            },
          }
        }
        return msg
      })
    })
    scrollToView()
  }

  const formatDate = (date: string) => format(date, 'MM/dd/yyyy kk:mm')
  const hasComments = message.comments?.length > 0 && !isEditQuestionOpen && !updating
  const showComments = message.commentState?.showComments

  const showInput = !updating && message.commentState?.showInput

  const isEditComment = (commentId: string) => {
    return !updating && message.comments.find(comment => comment.id === commentId && !!comment.isEditMode)
  }

  const toggleCommentEdit = (messageId: string, commentId?: string) => {
    setConversation(prev => {
      return prev.map(msg => {
        if (msg.id === messageId) {
          return {
            ...msg,
            comments: msg.comments.map(comment =>
              comment.id === commentId ? { ...comment, isEditMode: !comment.isEditMode } : comment,
            ),
            commentState: {
              ...msg.commentState,
              showInput: !msg.commentState?.showInput,
              showComments: msg.commentState?.showComments ?? false,
            },
          }
        }
        return msg
      })
    })
  }

  const showIcons = !isTaskRunning && !updating && message.content && !isEditQuestionOpen

  return (
    <div className="relative gap-4" data-qa-container>
      {!isEditQuestionOpen && !updating && (
        <div
          className={clsx(message.type === MessageType.QUESTION ? ' justify-end' : 'flex justify-start', 'flex mt-3')}
        >
          <div
            className={clsx(
              message.type === MessageType.QUESTION ? ' bg-[#E0EBFF] rounded-8 p-3' : 'flex justify-start ',
            )}
          >
            <Markdown
              className={clsx(
                message.type === MessageType.QUESTION && stylesMain.blackPMobile,
                message.type === MessageType.ANSWER && stylesMain.captionregular,
                'whitespace-pre-wrap',
              )}
            >
              {message.content}
            </Markdown>
          </div>
        </div>
      )}

      {hasRequiredPermission(userRole, [VIEW_COMMENT]) && hasComments && (
        <div className={clsx('absolute', message.type === MessageType.QUESTION ? 'right-0' : 'left-0')}>
          <button
            className="text-sm text-[#0014CC] mt-1"
            onClick={() => {
              handleToggleComments(message.id)
            }}
          >
            <span>
              {message.comments.length} Comment{message.comments.length > 1 && 's'}
            </span>
          </button>
        </div>
      )}

      {showIcons && (
        <div
          className={clsx(
            'flex justify-end sticky right-0 bottom-2 ml-auto w-1/2 h-[35px]',
            message.type === MessageType.QUESTION && hasComments ? '-mt-10' : '-mt-4',
          )}
        >
          <div
            className={clsx('bg-white shadow-md p-1 rounded-8 flex flex-row justify-between px-[3px]')}
            data-qa-editable
          >
            {hasRequiredPermission(userRole, [ADD_COMMENT]) && (
              <WppTooltip text="Comments">
                <WppActionButton
                  onClick={() => {
                    handleToggleComments(message.id)
                  }}
                >
                  <WppIconChatMessage />
                </WppActionButton>
              </WppTooltip>
            )}
            {hasRequiredPermission(userRole, [COPY_TO_CLIPBOARD]) && (
              <WppTooltip text="Copy to clipboard">
                <WppActionButton onClick={copyToClipboard} disabled={isPending}>
                  <WppIconCopy size="s" />
                </WppActionButton>
              </WppTooltip>
            )}
            {hasRequiredPermission(userRole, [EDIT_RFI_QUESTION]) &&
              message.type === MessageType.ANSWER &&
              (isPending ? (
                <WppSpinner className="cursor-not-allowed" />
              ) : (
                <WppTooltip text="Edit">
                  <WppActionButton
                    onClick={() => {
                      setIsEditQuestionOpen(true)
                      setConversation(prev =>
                        prev.map(msg => ({ ...msg, commentState: { showInput: false, showComments: false } })),
                      )
                    }}
                  >
                    <WppIconEdit size="s" />
                  </WppActionButton>
                </WppTooltip>
              ))}
          </div>
        </div>
      )}

      {hasRequiredPermission(userRole, [VIEW_COMMENT]) && showComments && (
        <div className={clsx('flex gap-2 flex-col items-end mt-8')}>
          {message.comments.map(item => (
            <div key={item.id} className="p-3 bg-[#F8F9FB] w-[90%]">
              <div className="flex justify-between">
                <WppTypography type="xs-midi">{item.createdBy.name}</WppTypography>
                <WppTypography type="xs-midi">
                  {format(item.createdAt, 'MM/dd/yyyy kk:mm')}{' '}
                  {item.updatedAt && (
                    <WppTooltip text={`Edited at ${formatDate(item.updatedAt)}`}>
                      {item.updatedAt && <span className="text-[#827b7b] text-xs">(edited)</span>}
                    </WppTooltip>
                  )}
                </WppTypography>
              </div>

              <>
                {isEditComment(item.id) ? (
                  hasRequiredPermission(userRole, [EDIT_COMMENT]) && (
                    <div className="w-[95%] ml-auto mt-2">
                      <CommentInput
                        handleSubmit={(commentText, action) => {
                          return action === 'edit'
                            ? handleEditComment(commentText, item.id)
                            : handlePostComment(commentText)
                        }}
                        isReplyLoading={isEditingComment}
                        initialComment={item.commentText}
                        projectMembers={members}
                        isEdit
                        handleCloseEdit={() => toggleCommentEdit(message.id, item.id)}
                      />
                    </div>
                  )
                ) : (
                  <div className="flex justify-between items-end gap-5">
                    <WppTypography type="s-body">{item.commentText}</WppTypography>
                    <WppMenuContext dropdownConfig={{ placement: 'bottom-end' }} className="w-auto">
                      {(userRole === Role.Owner ||
                        (hasRequiredPermission(userRole, [EDIT_COMMENT, DELETE_COMMENT]) &&
                          isCommentOwner(item.createdBy.email, currentUserEmail))) && (
                        <WppIconMore
                          slot="trigger-element"
                          direction="horizontal"
                          className="cursor-pointer mt-2 w-auto"
                        />
                      )}
                      {isCommentOwner(item.createdBy.email, currentUserEmail) && (
                        <WppListItem
                          onWppChangeListItem={() => {
                            toggleCommentEdit(message.id, item.id)
                          }}
                        >
                          <WppIconUserTag slot="left" />
                          <p slot="label">Edit</p>
                        </WppListItem>
                      )}
                      {(userRole === Role.Owner || isCommentOwner(item.createdBy.email, currentUserEmail)) && (
                        <WppListItem
                          onWppChangeListItem={() => handleDeleteComment({ messageId: message.id, commentId: item.id })}
                        >
                          <WppIconTrash slot="left" />
                          <p slot="label">Delete Comment</p>
                        </WppListItem>
                      )}
                    </WppMenuContext>
                  </div>
                )}
              </>
            </div>
          ))}
          <div ref={scrollDivRef} />
        </div>
      )}

      {/* show comment editor */}
      {hasRequiredPermission(userRole, [ADD_COMMENT]) && showInput && (
        <div className="mt-2 flex justify-end">
          <div className="w-[90%]">
            <CommentInput
              handleSubmit={(commentText, _action, mentions) => handlePostComment(commentText, mentions)}
              isReplyLoading={isPostingComment}
              projectMembers={members}
            />
          </div>
          <div ref={scrollDivRef} />
        </div>
      )}

      {!!updating && (
        <div className="flex justify-center gap-4 mt-4">
          <LoaderProgressWithDescription taskStatus={taskStatus} />
        </div>
      )}

      {isEditQuestionOpen && (
        <>
          <WppTextareaInput
            name="edit-content"
            placeholder="Ask a question to explore more options..."
            value={content}
            className={clsx(styles.textarea, message.content.length < 180 ? styles.shortContent : styles.longContent)}
            onWppChange={handleChange}
          />
          <div className="flex justify-end gap-4">
            <div className="cursor-pointer" onClick={handleCloseEdit}>
              <WppIconClose />
            </div>
            {isPending ? (
              <WppSpinner />
            ) : (
              <div className="cursor-pointer" onClick={() => handleSubmit(rfiQuestionId, content)}>
                <WppIconDone />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
