import { WppToastContainer } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { useClients } from 'api/queries/clients/useClients'
import { useRfis } from 'api/queries/rfis/useRfis'
import styles from 'app/App.module.scss'
import RoutesCmp from 'app/Routes'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ClientListContext } from 'contexts/ClientList'
import { ProjectContext } from 'contexts/Project'
import { ProjectDetails, RfiCreateProps } from 'types/rfis/rfi'

export const EMPTY_PROJECT: RfiCreateProps = {
  fileKey: '',
  projectName: '',
  client: '',
  pitchTypeIds: [],
  activeStatus: 0,
  marketIds: [],
  agencies: [],
  projectMembers: [],
}

const EMPTY_STATE = {
  newProject: EMPTY_PROJECT,
  newTaskStarted: null,
  projectFiles: null,
  projects: [],
  isFetchingProjects: false,
  isLoadingProjects: false,
}

export interface ProjectState {
  newProject: RfiCreateProps
  newTaskStarted: ProgressApiRes | null
  projectFiles: File[] | null
  isCreatingProject?: boolean
  projects: ProjectDetails[]
  isFetchingProjects: boolean
  isLoadingProjects: boolean
}

export function App() {
  const [emptyState, setState] = useState<ProjectState>(EMPTY_STATE)
  const { osContext } = useOs()
  const { data: projects = [], isLoading: isLoadingProjects, isFetching: isFetchingProjects } = useRfis()

  const { data: clientsData } = useClients()

  const { permissions } = osContext
  const state = { ...emptyState, permissions, projects, isLoadingProjects, isFetchingProjects }
  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.fullWidthContainer}>
        <div className={styles.container}>
          <div className={styles.pageContent}>
            <ProjectContext.Provider value={{ state, setState }}>
              <ClientListContext.Provider value={{ clients: clientsData }}>
                <RoutesCmp />
              </ClientListContext.Provider>
            </ProjectContext.Provider>
          </div>
          <WppToastContainer />
        </div>
      </div>
    </BrowserRouter>
  )
}
