import {
  WppFileUpload,
  WppInlineMessage,
  WppInput,
  WppListItem,
  WppSelect,
  WppTypography,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCreateRfi } from 'api/mutations/rfis/useCreateRfi'
import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useFileUploadUrls } from 'api/queries/files/useFileUploadUrls'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { EMPTY_PROJECT, ProjectState } from 'app/App'
import { queryClient } from 'app/Root'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import { UserCollaboration } from 'components/users/UserCollaboration'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'
import RfiSummaryError from 'pages/rfi-summary/RfiSummaryError'
import { ClientSelect } from 'pages/settings/useCases/useCasesEditAdd/clientSelect/ClientSelect'
import { RfiCreateProps } from 'types/rfis/rfi'
import { GetRfiReturn, Role } from 'types/users/userList'

import style from './NewProjectPage.module.scss'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

const UPLOAD_PROCESSING_TIMER = 300

export default function NewProjectPage() {
  const [uploadTimer, setUploadTimer] = useState(UPLOAD_PROCESSING_TIMER)
  const [showError, setShowError] = useState(false)
  const [uploadingFile, setFileUploading] = useState<File[] | []>([])

  const saveProject = useCreateRfi()
  const navigate = useNavigate()
  const { data: markets = [] } = useMarkets()
  const { data: agencies = [] } = useGetAgencies()
  const { data: regions = [] } = useRegions()
  const { data: pitchTypes = [] } = usePitchTypes()
  const { state, setState } = useProjectContext()
  const [{ data: fileUploadUrls }, generateFileUploadUrls] = useFileUploadUrls()

  const { data: taskStatus } = useTasksStatus({
    enabled: !!state.newTaskStarted?.id,
    refetchInterval: 3000,
    params: { taskId: state.newTaskStarted?.id || '' },
  })

  const toast = useToast()
  const isTaskRunning = state.newTaskStarted !== null && !taskStatus.completed

  useEffect(() => {
    if (taskStatus?.completed && !taskStatus.error) {
      // make sure our updates are handled asynchronously
      queryClient
        .invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
        .then(() => {
          toast.showToast({
            message: 'Project created successfully',
            type: 'success',
          })

          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: {
              action: ANALYTICS_EVENTS.NEW_PROJECT_CREATED_ACTION,
              params: [
                {
                  key: 'agencies',
                  value: state.newProject?.agencies?.join(', ') || '',
                },
                {
                  key: 'client',
                  value: state.newProject?.client || '',
                },
                {
                  key: 'pitch',
                  value: state.newProject?.pitchTypeIds?.join(', ') || '',
                },
                {
                  key: 'markets',
                  value: state.newProject?.marketIds?.join(', ') || '',
                },
                {
                  key: 'members',
                  value: (state.newProject?.projectMembers.length + 1).toString() || '',
                },
              ],
            },
          })

          navigate(`/rfi-helper-tool/rfi-summary/${taskStatus?.resultObjectId || ''}`)
          handleUsersUpdated([])
        })
        .then(() => {
          setState(prev => ({
            ...prev,
            projectId: taskStatus?.resultObjectId || null,
            newTaskStarted: null,
            projectFiles: null,
            newProject: EMPTY_PROJECT,
          }))
        })
    }
    if (taskStatus?.error) {
      setState(prev => ({
        ...prev,
        newTaskStarted: null,
      }))
      setShowError(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatus?.completed])

  // stop request if request takes longer than 5 mins
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> // Use ReturnType for correct type
    if (isTaskRunning && uploadTimer === 0) {
      queryClient.cancelQueries({ queryKey: [ApiQueryKeys.TASKS_STATUS] })
      setState(prev => ({
        ...prev,
        newProject: EMPTY_PROJECT,
        newTaskStarted: null,
        projectFiles: null,
        isFetchingProjects: false,
        isLoadingProjects: false,
      }))
      setShowError(true)
    }
    if (isTaskRunning && uploadTimer > 0) {
      intervalId = setInterval(() => {
        setUploadTimer(prev => --prev)
      }, 1000)
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [uploadTimer, isTaskRunning, setState])

  const updateState = (
    key: keyof RfiCreateProps,
    value: string | string[] | { member: string; role: keyof typeof Role }[],
  ) => {
    setState(
      prev =>
        ({
          ...prev,
          newProject: {
            ...prev.newProject,
            [key]: value,
          },
        }) as ProjectState,
    )
  }

  const handleFileUploadChange = async (e: CustomEvent) => {
    setFileUploading(e.detail.value)
    if (e.detail.value.length) {
      generateFileUploadUrls({ file_names: e.detail.value.map((file: any) => file.name), file_type: 'rfi' })
    }
  }

  const onRetryNewProject = () => {
    setShowError(false)
    setUploadTimer(UPLOAD_PROCESSING_TIMER)
    handleUsersUpdated([])
  }

  const handleUsersUpdated = (updatedUsers: GetRfiReturn[]) => updateState('projectMembers', updatedUsers)

  const uploadDisabled =
    !state.newProject?.client ||
    !state.newProject?.projectName ||
    // !state.newProject?.pitchTypeId ||
    !state.newProject?.pitchTypeIds ||
    !state.newProject.agencies.length ||
    (state.newProject?.marketIds?.length || 0) < 1 ||
    state.isCreatingProject

  const disabledInputsCondition = state.isCreatingProject || isTaskRunning

  useEffect(() => {
    // componentunmount
    // empty new projectstate
    return () => {
      setState(prev => ({
        ...prev,
        newProject: EMPTY_PROJECT,
        newTaskStarted: null,
        projectFiles: null,
        isFetchingProjects: false,
        isLoadingProjects: false,
      }))
    }
  }, [setState])

  useEffect(() => {
    if (fileUploadUrls && fileUploadUrls.length) {
      uploadFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadUrls])

  const uploadFiles = async () => {
    if (fileUploadUrls && fileUploadUrls.length && uploadingFile) {
      fileUploadUrls.forEach(async fileUrl => {
        try {
          const formDataAPI = new FormData()
          const files = uploadingFile.map(file => new Blob([file], { type: 'application/pdf' }))
          const file = files[0]
          formDataAPI.append('file', file, fileUrl.name)
          await fetch(fileUrl.signed_url, {
            headers: {
              'Content-Type': 'application/pdf',
              'x-goog-content-length-range': '1,1000000000',
            },
            body: formDataAPI,
            method: 'put',
          })

          try {
            setState(prev => ({ ...prev, projectFiles: uploadingFile, isCreatingProject: true }))
            saveProject
              .mutateAsync({
                ...state.newProject!,
                activeStatus: 1,
                agencies: state.newProject.agencies.map(agency => ({ id: agency })) as { id: string }[],
                projectMembers: state.newProject.projectMembers,
                fileKey: fileUrl.key,
              })
              /* now upload a file */
              .then(result => {
                setState(prev => ({
                  ...prev,
                  newTaskStarted: result.data,
                  isCreatingProject: false,
                }))
              })
            setState(prev => ({
              ...prev,
              newTaskStarted: null,
              isCreatingProject: false,
            }))
          } catch (e) {
            toast.showToast({
              message: 'Something went wrong while adding the agency. Please try again.',
              type: 'error',
            })
          }
          queryClient.resetQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
        } catch (e) {
          console.error('Error editing project data', e)
        }
      })
    }
  }

  return (
    <>
      <WppTypography type="2xl-heading" className={style.newProjectTitle}>
        New Project
      </WppTypography>
      <div className="flex flex-col gap-3 bg-[#FFFFFF] px-[15px] pb-[15px] rounded-lg">
        {showError && <RfiSummaryError onRetry={onRetryNewProject} />}
        {!showError && (
          <>
            <div className="mt-4 flex flex-col gap-3 bg-[#FFFFFF]">
              <WppTypography type="xl-heading">Information</WppTypography>
              <div className="flex flex-row gap-3">
                <div className="flex-1">
                  <WppInput
                    value={state.newProject?.projectName}
                    disabled={disabledInputsCondition || undefined}
                    onWppChange={e => updateState('projectName', e.detail.value || '')}
                    required
                    labelConfig={{
                      text: 'Project Name',
                    }}
                  />
                </div>
                <div className="flex-1">
                  <WppSelect
                    disabled={disabledInputsCondition || undefined}
                    value={state.newProject?.pitchTypeIds || []}
                    onWppChange={e => updateState('pitchTypeIds', e.detail.value || '')}
                    required
                    type="multiple"
                    withSearch
                    withFolder
                    labelConfig={{
                      text: 'Pitch Type',
                    }}
                  >
                    {pitchTypes
                      .toSorted((a, b) => a.typeDescription.localeCompare(b.typeDescription))
                      .map(pitchType => (
                        <WppListItem key={pitchType.id} value={pitchType.id}>
                          <p slot="label">{pitchType.typeDescription}</p>
                        </WppListItem>
                      ))}
                  </WppSelect>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                {/* CLIENT */}
                <ClientSelect
                  disabled={disabledInputsCondition || undefined}
                  value={state.newProject?.client || ''}
                  onChange={value => updateState('client', value)}
                />

                <div className="flex-1">
                  <WppSelect
                    required
                    disabled={disabledInputsCondition || undefined}
                    withSearch={true}
                    showSelectAllText={true}
                    type="multiple"
                    value={state.newProject?.marketIds || []}
                    onWppChange={e => updateState('marketIds', e.detail.value)}
                    labelConfig={{
                      text: 'Markets',
                    }}
                  >
                    {[...regions, ...markets].map(market => (
                      <WppListItem key={market.id} value={market.id}>
                        <p slot="label">{market.name}</p>
                      </WppListItem>
                    ))}
                  </WppSelect>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex-1">
                  <WppSelect
                    disabled={disabledInputsCondition || undefined}
                    value={state.newProject?.agencies}
                    required
                    labelConfig={{
                      text: 'Agency',
                    }}
                    placeholder="Select Agency"
                    withSearch={true}
                    onWppChange={e => updateState('agencies', e.detail.value)}
                    showSelectAllText={true}
                    type="multiple"
                  >
                    {agencies
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(agency => {
                        return (
                          <WppListItem key={agency.id} value={agency.id}>
                            <p slot="label">{agency.name}</p>
                          </WppListItem>
                        )
                      })}
                  </WppSelect>
                  <WppInlineMessage
                    className={style.inputInlineMessage}
                    message="Select the relevant agency (one or more) and its knowledge base for this project."
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col pt-4 bg-[#FFFFFF]">
              <UserCollaboration
                initUsers={[]}
                disabledInputsCondition={disabledInputsCondition}
                isTaskRunning={isTaskRunning}
                onUsersUpdated={handleUsersUpdated}
              />
            </div>

            <WppTypography type="xl-heading" className="mt-4">
              Upload files
            </WppTypography>
            <WppFileUpload
              name="rfiProjectPdf"
              disabled={uploadDisabled || isTaskRunning}
              acceptConfig={{ 'application/pdf': ['.pdf'] }}
              onWppChange={handleFileUploadChange}
              format="arrayBuffer"
              className={style.fileUpload}
              controlled
              value={uploadingFile}
            />
            {/* TODO: adjust it once the backed is ready to upload RFI */}
            {(state.isCreatingProject || isTaskRunning) && (
              <div className="h-[146px] rounded-lg w-full flex flex-row items-center justify-center p-[10px] bg-[#F8F9FB]">
                <LoaderProgressWithDescription taskStatus={taskStatus} />
              </div>
            )}
            {(state.isCreatingProject || state.newTaskStarted !== null) && !taskStatus.completed ? (
              <div className="flex flex-row items-center justify-start bg-[#ffffcc] rounded-lg border border-solid border-[#ffeb3b] gap-3 mt-4 px-5 py-4">
                <WppTypography type="s-body">
                  The project will be created once the file is uploaded and processed. Please wait...
                </WppTypography>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}
