import {
  WppActionButton,
  WppIconAdd,
  WppIconChevron,
  WppInput,
  WppListItem,
  WppSelect,
} from '@wppopen/components-library-react'
import { useState } from 'react'

import useClientListContext from 'hooks/useClientListContext'

export interface Props {
  disabled?: boolean
  value: string
  onChange: (value: string) => void
}

export const ClientSelect = ({ disabled = false, value, onChange }: Props) => {
  const { clients } = useClientListContext()
  const [addClientMode, setAddClientMode] = useState(false)
  return (
    <div className="flex flex-1 gap-1 w-full items-end">
      {addClientMode ? (
        <WppInput
          disabled={disabled || undefined}
          aria-label="Client"
          title="Client"
          placeholder="Client name"
          labelConfig={{
            text: 'Client',
          }}
          value={value}
          required
          className="w-full"
          onWppChange={e => onChange(e.detail.value || '')}
        />
      ) : (
        <WppSelect
          disabled={disabled || undefined}
          className="w-full"
          aria-label="Client"
          title="Client"
          placeholder="Select client"
          labelConfig={{
            text: 'Client',
          }}
          withSearch
          withFolder
          value={value}
          required
          onWppChange={e => onChange(e.detail.value || '')}
        >
          {clients?.map(item => (
            <WppListItem key={item.id} value={item.id}>
              <p slot="label">{item.name}</p>
            </WppListItem>
          ))}
        </WppSelect>
      )}

      <WppActionButton
        variant="secondary"
        className="h-[40px]"
        onClick={() => {
          setAddClientMode(!addClientMode)
          onChange('')
        }}
      >
        {addClientMode ? <WppIconChevron slot="icon-end" direction="down" /> : <WppIconAdd slot="icon-end" />}
      </WppActionButton>
    </div>
  )
}
